<template>
  <v-list dense nav>
    <v-list-item class="px-0">
      <v-list-item-avatar>
        <v-img
          v-if="getThemeMode.verticalSidebarDrawerColor == 'dark'"
          src="@/assets/images/common/kimCare_logo2.png"
        />
        <v-img v-else src="@/assets/images/common/kimCare_logo2.png" />
      </v-list-item-avatar>

      <v-list-item-title class="text-18 text-uppercase text-default">
        <img
          class="typoImg"
          src="@/assets/images/common/kimCare_typo.png"
          width="130"
        />
      </v-list-item-title>

      <v-scroll-x-transition>
        <v-btn icon @click.stop="toggleSidebar">
          <v-tooltip
            v-if="!getThemeMode.verticalSidebarMini"
            right
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" color v-on="on">
                mdi-circle-slice-8
              </v-icon>
            </template>
            <span>UnPin</span>
          </v-tooltip>
          <v-tooltip
            v-if="getThemeMode.verticalSidebarMini"
            right
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon dark v-bind="attrs" color v-on="on">
                mdi-circle-outline
              </v-icon>
            </template>
            <span>pin</span>
          </v-tooltip>
        </v-btn>
      </v-scroll-x-transition>
    </v-list-item>
  </v-list>
</template>
<script>
export default {
  props: {
    getThemeMode: {
      type: [Object, Array],
    },
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
  },
};
</script>
<style lang="scss">
.typoImg {
  width: 170px !important;
}
</style>