import i18n from '@/lang/lang';
export const items = [
  //Dashboard
  {
    title: i18n.t('menu.dashBoard'),
    icon: "mdi-monitor-dashboard",
    to: "/dashboard",
    group: "dashboard"
  },
  //Clinic-환자
  // {
  //   title: i18n.t('menu.userBooking'),
  //   icon: "mdi-account",
  //   to: "/userBooking",
  //   group: "userBooking"
  // },
  //Clinic-환자
  {
    title: i18n.t('menu.consultingUser'),
    icon: "mdi-account",
    to: "/consulting",
    group: "consulting"
  },
  //Clinic
  {
    title: i18n.t('menu.clinic'),
    icon: "mdi-hospital",
    to: "/clinic",
    group: "clinic"
  },
  //Clinic Staff
  {
    title: "병원관리자 정보",
    icon: "mdi-doctor",
    to: "/staff",
    group: "staff"
  },
  //Patient
  {
    title: i18n.t('menu.patient'),
    icon: "mdi-account",
    to: "/patient",
    group: "patient"
  },
  //Risk Setting
  // {
  //   title: "Risk Setting",
  //   icon: "mdi-close-octagon",
  //   to: "/risk",
  //   group: "risk"
  // },
  // //Alert Setting
  // {
  //   title: "Alert Setting",
  //   icon: "mdi-alert",
  //   to: "/alert",
  //   group: "alert"
  // },
  // setting -> 공통관리
  {
    title: i18n.t('menu.setting'),
    icon: "mdi-cog",
    to: "/setting",
    group: "setting"
  },
  //Billing
  {
    title: i18n.t('menu.billing'),
    icon: "mdi-calculator",
    to: "/billing",
    group: "billing"
  },
  {
    title: i18n.t('menu.statistics'),
    icon: "mdi-dashboard",
    to: "/Statistics",
    group: "Statistics"
  },
  //Device
  // {
  //   title: "Device",
  //   icon: "mdi-devices",
  //   to: "/device",
  //   group: "device"
  // },
  {
    title: i18n.t('menu.contents'),
    icon: "mdi-rss-box",
    to: "/contents",
    group: "contents"
  },
  //Direct QnA
  {
    title: i18n.t('menu.directQna'),
    icon: "mdi-forum",
    to: "/qna",
    group: "qna"
  },
  //add headOffice -> 솔루션 사용 주체 
  {
    title: i18n.t('menu.headOffice'), //"Add Head Office",
    icon: "mdi-office-building",
    to: "/addHeadOffice",
    group: "addHeadOffice"
  },
  {
    title: i18n.t('menu.test'),
    icon: "mdi-test-tube",
    to: "/test",
    group: "test"
  },
];

// export const itemsKo = [
//   //Patient
//   {
//     title: "환자 정보",
//     icon: "mdi-account",
//     to: "/patient",
//     group: "patient"
//   },

//   //Clinic
//   {
//     title: "병의원 정보",
//     icon: "mdi-hospital",
//     to: "/clinic",
//     group: "clinic"
//   },

//   //Clinic Staff
//   {
//     title: "직원 정보",
//     icon: "mdi-doctor",
//     to: "/clinicStaff",
//     group: "clinicStaff"
//   },

//   //Risk Setting
//   {
//     title: "위험 기준 설정",
//     icon: "mdi-close-octagon",
//     to: "/risk",
//     group: "risk"
//   },

//   //Alert Setting
//   {
//     title: "알림 설정",
//     icon: "mdi-alert",
//     to: "/alert",
//     group: "alert"
//   },

//   //Medicine
//   // {
//   //   title: 'Medicine',
//   //   icon: 'mdi-pill',
//   //   to: '/medicine',
//   //   group: 'medicine',
//   // },

//   //Billing
//   // {
//   //   title: 'Billing',
//   //   icon: 'mdi-calculator',
//   //   to: '/billing',
//   //   group: 'billing',
//   // },

//   //Device
//   {
//     title: "디바이스",
//     icon: "mdi-devices",
//     to: "/device",
//     group: "device"
//   },

//   //Direct QnA
//   {
//     title: "1:1 Q&A",
//     icon: "mdi-forum",
//     to: "/qna",
//     group: "qna"
//   }
// ];
